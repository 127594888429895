<template>
  <div>
      <!-- <search/>
      <about/>
      <answerCall/>
      <createClient/>
      <bankproducts/>
      <calculatormodal/>
      <cardstatusmodal/>
      <selectedprolems/>
      <depositmodal/>
      <dropdown/>
      <header />
      <historyproblems/>
      <historyproblems2/>
      <mikrozaymmodal/>
      <problems/>
      <container/>      
      <createProblem/>
      <creditmodal/>
      <credittable/> -->
  </div>
</template>

<script>


// import search from "../components/htmls/search.vue";
// import about from "../components/htmls/about.vue";
// import answerCall from "../components/htmls/answerCall.vue";
// import bankproducts from "../components/htmls/bankproducts.vue";
// import calculatormodal from "../components/htmls/calculatormodal.vue";
// import cardstatusmodal from "../components/htmls/cardstatusmodal.vue";
// import selectedprolems from "../components/htmls/selectedprolems.vue";
// import depositmodal from "../components/htmls/depositmodal.vue";
// import dropdown from "../components/htmls/dropdown.vue";
// import header from "../components/htmls/header.vue";
// import historyproblems from "../components/htmls/historyproblems.vue";
// import historyproblems2 from "../components/htmls/historyproblems2.vue";
// import mikrozaymmodal from "../components/htmls/mikrozaymmodal.vue";
// import problems from "../components/htmls/problems.vue";
// import container from "../components/htmls/container.vue";
// import createClient from "../components/htmls/createClient.vue";
// import createProblem from "../components/htmls/createProblem.vue";
// import creditmodal from "../components/htmls/creditmodal.vue";
// import credittable from "../components/htmls/credittable.vue";
export default {
    // components: {
    //    search,
    //    about,
    //    answerCall,
    //    bankproducts,
    //    calculatormodal,
    //    cardstatusmodal,
    //    selectedprolems,
    //    depositmodal,
    //    dropdown,
    //    header,
    //    historyproblems,
    //    historyproblems2,
    //    mikrozaymmodal,
    //    problems,
    //    container,
    //    createClient,
    //    createProblem,
    //    creditmodal,
    //    credittable
    // },
  name: 'Page500'
}
</script>


